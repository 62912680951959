import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
} from "../../../../styles/theme";
import Image from "../../Atoms/Image";
import Button from "../../Atoms/Button";
import RichText from "../../Atoms/RichText";
import {
  Noto,
  NotoSmall,
  NotoBody,
  OswaldOverline3,
  OswaldOverline2,
} from "../../Atoms/Typography";

const Wrapper = styled.section`
  padding: 0 calc(var(--external-gutter)) ${scaleSpacing(11)}
    calc(var(--external-gutter));
  ${mediaquery.md(css`
    padding: ${scaleSpacing(2)} calc(var(--external-gutter) + var(--col))
      ${scaleSpacing(21)} calc(var(--external-gutter) + var(--col));
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.darkgrey2};
  display: flex;
  flex-direction: column;
  padding-bottom: ${scaleSpacing(16)};

  ${mediaquery.md(css`
    justify-content: space-between;
    flex-direction: row;
  `)};

  &:last-of-type {
    padding-bottom: 0;
  }
`;

const Title = styled.h2`
  ${OswaldOverline2};
  color: ${colors.lightgrey};
  margin-bottom: ${scaleSpacing(8)};

  ${mediaquery.md(css`
    ${OswaldOverline3};
  `)};
`;

const CollabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${scaleSpacing(5.25)};
  ${Noto};
  font-size: ${rem(11)};
  line-height: ${rem(14)};
  letter-spacing: ${rem(0.2)};
  font-weight: 500;
  color: ${colors.mediumgrey3};

  ${mediaquery.md(css`
    margin-bottom: ${scaleSpacing(8)};
  `)};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${scaleSpacing(2)};
  width: ${scaleSpacing(14)};
  height: ${rem(40)};

  .gatsby-image-wrapper {
    display: flex;
    align-items: center;
    height: 50%;
  }

  img {
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Content = styled.div`
  ${NotoBody};
  line-height: ${rem(30)};
  color: ${colors.lightgrey};

  ${mediaquery.md(css`
    width: calc(var(--col) * 12 - var(--gutter-size));
  `)};
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: ${scaleSpacing(5.5)};
  margin-bottom: ${scaleSpacing(6)};
  border-bottom: 1px solid ${colors.darkgrey1};

  ${mediaquery.sm(css`
    padding-bottom: ${scaleSpacing(4.5)};
  `)};

  &:last-of-type {
    margin-bottom: 0;
  }
  span {
    ${NotoBody}
    color: ${colors.white};
    &:last-of-type {
      color: ${colors.mediumgrey3};
    }
  }
`;

const TagList = styled.ul`
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;

  .cols2 && {
    margin: ${scaleSpacing(6)} 0;
  }
`;

const TagListItem = styled.li`
  display: flex;
  position: relative;
  margin: 0 ${scaleSpacing(6)} ${scaleSpacing(6)} 0;

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledTag = styled(Button)`
  ${NotoSmall};
  line-height: ${rem(16)};
  background-color: transparent;
  color: ${colors.mediumgrey3};
  padding: ${scaleSpacing(1)} 0;
  text-transform: none;
  padding: ${scaleSpacing(2)} ${scaleSpacing(3)};
  border-radius: ${rem(60)};
  background-color: rgba(64, 64, 64, 0.5);
  cursor: default;
`;

const Distribution = ({
  actors,
  description,
  sponsorsDescription,
  sponsors,
  category,
  genre,
  language,
  tags,
  titleIntro,
  titleActors,
  titleTags,
}) => {
  return (
    <Wrapper>
      {!!description && (
        <Container>
          <Title>{titleIntro}</Title>
          <Content>
            <RichText data={description} />
            {sponsors?.length > 0 && (
              <CollabWrapper>
                {sponsorsDescription && (
                  <span>
                    <RichText data={sponsorsDescription} />
                  </span>
                )}
                {sponsors.map(({ logo, uid }) => {
                  if (!uid) return null;
                  return (
                    <LogoContainer key={uid}>
                      <Image
                        source={logo}
                        sizes="40"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                        }}
                        imgStyle={{
                          height: "auto",
                          width: "100%",
                          margin: "auto",
                          bottom: 0,
                          right: 0,
                        }}
                        isFixed
                      />
                    </LogoContainer>
                  );
                })}
              </CollabWrapper>
            )}
          </Content>
        </Container>
      )}
      {actors.length > 0 && (
        <Container>
          <Title>{titleActors}</Title>
          <Content>
            {actors && (
              <List>
                {actors.map(({ name, role }) => (
                  <Item key={name?.replace(/\W/g, "_")}>
                    {name && <span>{name}</span>}
                    {role && <span>{role}</span>}
                  </Item>
                ))}
              </List>
            )}
          </Content>
        </Container>
      )}

      {(tags?.length > 0 || !!category || !!language || !!genre) && (
        <Container>
          <Title>{titleTags}</Title>
          <Content>
            <TagList>
              {category && (
                <TagListItem>
                  <StyledTag>{category}</StyledTag>
                </TagListItem>
              )}

              {genre && (
                <TagListItem>
                  <StyledTag>{genre}</StyledTag>
                </TagListItem>
              )}

              {language && (
                <TagListItem>
                  <StyledTag>{language}</StyledTag>
                </TagListItem>
              )}

              {tags?.length > 0 &&
                tags.map(({ label }) => {
                  if (!label) return null;
                  return (
                    <TagListItem key={label}>
                      <StyledTag>{label}</StyledTag>
                    </TagListItem>
                  );
                })}
            </TagList>
          </Content>
        </Container>
      )}
    </Wrapper>
  );
};

Distribution.propTypes = {
  description: PropTypes.array.isRequired,
  actors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      role: PropTypes.string,
    })
  ).isRequired,
  sponsorsDescription: PropTypes.array,
  sponsors: PropTypes.array,
  category: PropTypes.string,
  genre: PropTypes.string,
  language: PropTypes.string,
  tags: PropTypes.array,
  titleIntro: PropTypes.string,
  titleActors: PropTypes.string,
  titleTags: PropTypes.string,
};

export default React.memo(Distribution);
